import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { Dashboard1Component } from "./dashboard/dashboard1/dashboard1.component";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard], 
    children: [
      { path: "", redirectTo: '/authentication/signin', pathMatch: "full" },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "dashboard",
       component: Dashboard1Component,

      },
      {
        path: "enquiry",
        loadChildren: () =>
          import("./enquiry/enquiry.module").then((m) => m.EnquiryModule),
      },
      {
        path: "adminusers",
        loadChildren: () =>
          import("./adminusers/adminusers.module").then((m) => m.AdminusersModule),
      },
      {
        path: "user-details",
        loadChildren: () =>
          import("./udetails/udetails.module").then((m) => m.UdetailsModule),
      },
      {
        path: "unverified",
        loadChildren: () =>
          import("./unverified/unverified.module").then((m) => m.Unverified),
      },
      {
        path: "email-unverified",
        loadChildren: () =>
          import("./email-unverified/email-unverified.module").then((m) => m.EmailUnverifiedModule),
      },
      {
        path: "mobile-unverified",
        loadChildren: () =>
          import("./mobile-unverified/mobile-unverified.module").then((m) => m.MobileUnverifiedModule),
      },
      {
        path: "active-user",
        loadChildren: () =>
          import("./active-user/active-user.module").then((m) => m.ActiveUserModule),
      },
      {
        path: "inactive-user",
        loadChildren: () =>
          import("./inactive-user/inactive-user.module").then((m) => m.InactiveUserModule),
      },
      {
        path: "deactivate-user",
        loadChildren: () =>
          import("./deactivate-user/deactivate-user.module").then((m) => m.DeactivateUserModule),
      },
      {
        path: "region",
        loadChildren: () =>
          import("./region/region.module").then((m) => m.RegionModule),
      },
      {
        path: "applications",
        loadChildren: () =>
          import("./applications/applications.module").then((m) => m.ApplicationsModule),
      },

     
      {
        path: "coupon",
        loadChildren: () =>
          import("./coupon/coupon.module").then((m) => m.CouponModule),
      },
      {
        path: "country",
        loadChildren: () =>
          import("./country/country.module").then((m) => m.CountryModule),
      },
      {
        path: "currency",
        loadChildren: () =>
          import("./currency/currency.module").then((m) => m.CurrencyModule),
      },
      {
        path: "general-settings",
        loadChildren: () =>
          import("./general/general.module").then((m) => m.GeneralModule),
      },
      
    
  
      {
        path: "state",
        loadChildren: () =>
          import("./state/state.module").then((m) => m.StateModule),
      },
      {
        path: "tax",
        loadChildren: () =>
          import("./tax/tax.module").then((m) => m.TaxModule),
      },
  
     
     
 
      {
        path: "message", 
        loadChildren: () =>
          import("./email-verification/email-verification.module").then((m) => m.EmailVerificationModule),
      },

      {
        path: "pricing", 
        loadChildren: () =>
          import("./pricing/pricing.module").then((m) => m.PricingModule),
      },
      {
        path: "newsletter", 
        loadChildren: () =>
          import("./newsletter/newsletter.module").then((m) => m.NewsletterModule),
      },
      {
        path: "orders", 
        loadChildren: () =>
          import("./allorders/allorders.module").then((m) => m.AllordersModule),
      },
      {
        path: "api", 
        loadChildren: () =>
          import("./api/api.module").then((m) => m.ApiModule),
      },
      {
        path: "cplan", 
        loadChildren: () =>
          import("./custom-plan/custom-plan.module").then((m) => m.CustomModule),
      },
      {
        path: "planusage", 
        loadChildren: () =>
          import("./planusage/planusage.module").then((m) => m.PlanusageModule),
      },
      {
        path: "plancancellation", 
        loadChildren: () =>
          import("./plancancellation/plancancellation.module").then((m) => m.PlancancellationModule),
      },
      {
        path: "planexpiry", 
        loadChildren: () =>
          import("./planexpiry/planexpiry.module").then((m) => m.PlanexpiryModule),
      },

      {
        path: "invoice/:id", 
        loadChildren: () =>
          import("./invoice/invoice.module").then((m) => m.InvoiceModule),
      },
      
      {
        path: "tables",
        loadChildren: () =>
          import("./tables/tables.module").then((m) => m.TablesModule),
      },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

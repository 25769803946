// export const apiConfig = {
//     apigbe: 'http://localhost:4000/',
//     apilbe: 'http://localhost:4001/gapi',
//     apisbe: 'http://localhost:4002/gapi',
//     apinbe: 'http://localhost:4003/gapi' // Default API URL
//     // Default API URL
//   };

export const apiConfig = {
  apigbe: "https://ga.orcollate.xyz/api",
  apilbe: "https://list.orcollate.xyz/api",
  apisbe: "https://shop.orcollate.xyz/api",
  apinbe: "https://newsletter.orcollate.xyz/api", // Default API URL
  // Default API URL
};

<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item logo">
          <a class="navbar-brand" routerLink="dashboard/dashboard1">
            <img src="assets/images/logo.png" alt="" />
            <span class="logo-name">Orcollate</span>
          </a>
        </li>
        <li class="nav-item nav-toggle">
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon [ngStyle]="{'color':'#8F8C91'}" class="menuIcon">{{menuIcon}}</mat-icon>
          </button>
        </li>
      </ul>
    </div>
    <!-- Menu -->
    <div class="menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
        <ul class="list">
          <!-- Top Most level menu -->
          <ng-container *ngFor="let sidebarItem of sidebarItems">
            <li [routerLinkActive]="sidebarItem.submenu.length != 0 ? 'active' : 'active-top'"
              *ngIf="shouldShowMenuItem(sidebarItem)">
              <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
              <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
                [ngClass]="[sidebarItem.class]" (click)="callToggleMenu($event, sidebarItem.submenu.length)"
                class="menu-top">
                <i-feather [name]="sidebarItem.icon" class="sidebarIcon"></i-feather>
                <span class="hide-menu">{{sidebarItem.title | translate}}</span>
                <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
              </a>
              <!-- First level menu -->
              <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
                <li *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
                  [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'">
                  <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
                    (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)" [ngClass]="[sidebarSubItem1.class]">
                    {{sidebarSubItem1.title | translate}}
                  </a>
                  <!-- Second level menu -->
                </li>
              </ul>
            </li>
          </ng-container>
        </ul>

      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
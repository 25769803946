import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { User_Lists } from "../graphql/graphql.queries";
import { apiConfig } from '../api.config';

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private apollo: Apollo) {} 

  loginuser(username:string, password:string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation login(
          $email_mobile: String!
          $password: String!
        ) {
          login(
            email_mobile: $email_mobile
            password: $password
          ) {
            code
            message
            description
            token
          }
        }
      `,
      variables: {
        email_mobile: username,
        password: password,

      },
      context: {
        uri: apiConfig.apigbe // Set the API URL here
      }
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import { LoginService } from "src/app/services/login.service";
import { gql, Apollo } from "apollo-angular";
import { Router } from "@angular/router";
import * as jwtDecode from 'jwt-decode';
@Injectable({
  providedIn: "root",
})


export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;


  transtxt: any;
  datavalue: any[] = [];
  tokenval: number[] = [];

  constructor(
    private http: HttpClient,
    private login2: LoginService,
    private apollo: Apollo,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  decodeJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c: string) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  }

  login(username: string, password: string) {


    return this.apollo
      .mutate({
        mutation: gql`
          mutation login(
            $email_mobile: String!
            $password: String!
          ) {
            login(
              email_mobile: $email_mobile
              password: $password
            ) {
              code
              message
              token
            }
          }
        `,
        variables: {
          email_mobile: username,
          password: password,
        },
      })

      .subscribe(
        ({ data }: any) => {

          this.datavalue = data.login;
          
         if(this.datavalue[0].code=='200'){

          this.tokenval = this.decodeJwt(this.datavalue[0].token);

          this.transtxt = {
            "id": this.datavalue[0].token,
            "img": 'http://orcollate.xyz/assets/images/pages/testlogo.png',
            "username": this.tokenval['username'],
            "firstName": this.tokenval['username'],
            "lastName": this.tokenval['username'],
            "token": this.datavalue[0].token,
            "api_key": this.tokenval['apikey'],
            "ulevel": this.tokenval['ulevel']

          };
          localStorage.setItem("currentUser", JSON.stringify(this.transtxt));
          this.currentUserSubject.next(this.transtxt);
          this.router.navigate(["/user-details"])
        }
      }
      );

  }

  // login(username: string, password: string) {
  //   return this.http
  //     .post<any>(`${environment.apiUrl}/authenticate`, {
  //       username,
  //       password,
  //     })
  //     .pipe(
  //       map((user) => { 

     
          
  //         localStorage.setItem("currentUser", JSON.stringify(this.transtxt));
  //         console.log(this.transtxt);
  //         this.currentUserSubject.next(this.transtxt);
  //         return user;
  //       })
  //     );
  // }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}

import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    submenu: [],
  },
  
  {
    path: "dashboard/dashboard1",
    title: "MENUITEMS.DASHBOARD.TEXT",
    iconType: "feather",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
  },
  {
    path: "adminusers",
    title: "Admin Users",
    iconType: "feather",
    icon: "users",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
  },

  {
    path: "pricing",
    title: "Pricing",
    iconType: "feather",
    icon: "dollar-sign",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [
      
      {
        path: "applications",
        title: "Applications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },

      {
        path: "coupon",
        title: "Coupon",
        iconType: "feather",
        icon: "",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      
      {
        path: "pricing",
        title: "Pricing",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: "Reports",
    iconType: "feather",
    icon: "shopping-bag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [
      {
        path: "/orders",
        title: "Orders",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },

      {
        path: "/sales",
        title: "Sales",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
     
      
    ],
  },

  {
    path: "",
    title: "Settings",
    iconType: "feather",
    icon: "settings",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [
      
      {
        path: "country",
        title: "Country",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "state",
        title: "State",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "currency",
        title: "Currency",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
        
      
     
      {
        path: "message",
        title: "Message",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "api",
        title: "API",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
    
     
     
       
      
      {
        path: "tax",
        title: "Tax",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      
      
      
    ],
  },
  

  {
    path: "rn",
    title: "Activate",
    iconType: "feather",
    icon: "mail",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
  },

  {
    path: "user-details",
    title: "User Details",
    iconType: "feather",
    icon: "users",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
  },
  
  {
    path: "region",
    title: "Region",
    iconType: "feather",
    icon: "users",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
  },
  


  {
    path: "",
    title: "Plans",
    iconType: "feather",
    icon: "credit-card",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [
      {
        path: "/cplan",
        title: "Custom Plan Request",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "/planusage",
        title: "Plan Usage",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "/plancancellation",
        title: "Plan Cancellation",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "/planexpiry",
        title: "Plan Expiry",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      
    ],
  },

  

  

  {
    path: "",
    title: "Contact Us",
    iconType: "feather",
    icon: "smile",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [
      {
        path: "enquiry",
        title: "Enquiry",
        iconType: "",
        icon: "mail",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "newsletter",
        title: "Newsletter",
        iconType: "",
        icon: "mail",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      
      
      
    ],
  },

 
  
];
